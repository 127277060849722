<template>
    <div class="wrap">
        <div class="facilityBox">
            <!-- form表单 -->
            <el-form class="manageForm" :model="manageForm" :inline="true">
                <el-form-item label="企业名称：" prop="EnterpriseFullName">
                    <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称"
                        @keyup.enter.native="search()"></el-input>
                </el-form-item>
                <el-form-item label="手机号：" prop="LegalPersonlPhone">
                    <el-input class="fromInp" v-model="manageForm.LegalPersonlPhone" placeholder="输入手机号"
                        @keyup.enter.native="search()"></el-input>
                </el-form-item>
                <el-form-item label="提交日期：" prop="TaskDatetime">
                    <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至"
                        start-placeholder="开始日期" value-format="yyyy-MM-dd" end-placeholder="结束日期"
                        @change="searchChange()"></el-date-picker>
                </el-form-item>
                <el-form-item label-width="10px">
                    <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
                    <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
                <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="LegalPersonIName" label="法人姓名" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200"
                    show-overflow-tooltip></el-table-column>
                <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="200" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="CreatedTime" label="提交时间" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="UpdatedTime" label="变更时间" width="150" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="StatusName" label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-tag type="success">{{ scope.row.StatusName }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column align="center" prop="Remark" label="备注" width="200" fixed="right" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <el-alert :title="scope.row.Remark" type="warning" :closable="false"
                            v-if="scope.row.Remark"></el-alert>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" fixed="right" width="420">
                    <template slot-scope="scope">
                        <el-button type="primary" size="small" icon="el-icon-setting" @click="examineRate(scope.row)">
                            查看费率</el-button>
                        <el-button type="success" size="small" @click="agreeClick(scope.row)"
                            icon="el-icon-circle-check">同意</el-button>
                        <el-button type="danger" size="small" @click="backClick(scope.row)"
                            icon="el-icon-refresh-right">退回重设</el-button>
                        <el-button type="primary" size="small" @click="goFacility(scope.row, pagination.page)"
                            icon="el-icon-tickets">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top:10px">
                <!-- 分页 -->
                <el-pagination background class="pagination" @current-change="handleCurrentChange"
                    :current-page.sync="pagination.page" :page-size="pagination.pagesize"
                    layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
            </div>
        </div>
        <!-- 驳回弹窗 -->
        <el-dialog title="驳回意见" :visible.sync="flag.centerDialogVisible" width="30%" center>
            <div style="margin:20px 0 0 0;">
                <el-input type="textarea" :rows="6" maxlength="100" show-word-limit placeholder="请输入驳回处理意见，限制100字。"
                    v-model="textarea"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="flag.centerDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="bindBack()">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 查看费率 -->
        <el-dialog :visible.sync="flag.rateMdel" width="70%" v-if="flag.rateMdel" class="rate-dialog">
            <span slot="title">查看费率</span>
            <el-tabs v-model="dialogName">
                <el-tab-pane label="服务商费率" name="first">
                    <div class="dialog_box">
                        <div class="dialogRole">
                            <strong>企业名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
                        </div>
                        <div class="dialogRole">
                            <strong>每月可开票最大金额：</strong><span style="color:orange">{{ TotalInvoiceAmount ? '￥'
                                + TotalInvoiceAmount : 0 }}</span><br />
                        </div>
                        <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                            v-loading="loading" max-height="500px">
                            <el-table-column align="center" label="发票-业务-场景">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.TaskSceneName">
                                        {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                                            scope.row.TaskSceneName
                                        }}</span>
                                    <span v-else>
                                        {{ scope.row.InvoiceTypeName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="费率" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ scope.row.Rate ? scope.row.Rate + '%' : scope.row.Rate }}
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="技术服务费费率" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    {{ scope.row.TecRate ? scope.row.TecRate + '%' : scope.row.TecRate }}
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button type="primary" @click="toDetail(scope.row)" :disabled="!scope.row.RateID">查看历史记录
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="代理商费率" name="second">
                    <div class="dialog_box">
                        <div class="dialogRole">
                            <strong>企业名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
                        </div>
                        <el-table :data="rateDataAgent" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
                            v-loading="loading" max-height="500px">
                            <el-table-column align="center" label="发票-业务-场景">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.TaskSceneName">
                                        {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                                            scope.row.TaskSceneName
                                        }}</span>
                                    <span v-else>
                                        {{ scope.row.InvoiceTypeName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="费率" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <span>{{ scope.row.Rate ? scope.row.Rate + '%' : scope.row.Rate }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column align="center" label="操作" show-overflow-tooltip>
                                <template slot-scope="scope">
                                    <el-button type="primary" @click="toDetail(scope.row)" :disabled="!scope.row.RateID">查看历史记录
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-tab-pane>
            </el-tabs>
            <span slot="footer" class="dialog-footer">
                <el-button @click="flag.rateMdel = false" size="small">关 闭</el-button>
            </span>
        </el-dialog>
        <!-- 查看费率历史记录 -->
        <el-dialog title="历史记录" :visible.sync="rateRecordDialog" width="40%" v-if="rateRecordDialog" class="rate-dialog">
            <div style="text-align: center;font-weight: bold;margin-bottom: 10px;">发票-业务-场景： <span
                    v-if="currentItem.TaskSceneName">
                    {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} / {{
                        currentItem.TaskSceneName
                    }}</span>
                <span v-else>
                    {{ currentItem.InvoiceTypeName }}</span>
            </div>
            <el-table :data="rateRecord" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
                <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
                </el-table-column>
                <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip>
                    <template slot-scope="scope">{{ scope.row.Rate ? scope.row.Rate + '%' : '' }}</template>
                </el-table-column>
                <el-table-column align="center" prop="TecRate" label="技术服务费费率" show-overflow-tooltip
                    v-if="dialogName == 'first'">
                    <template slot-scope="scope">{{ scope.row.TecRate ? scope.row.TecRate + '%' : '' }}</template>
                </el-table-column>
                <el-table-column align="center" prop="CteatedTime" label="变更时间" show-overflow-tooltip>
                </el-table-column>
            </el-table>
            <div style="margin-top:10px">
                <el-pagination background class="pagination" @current-change="handleCurrentChange1"
                    :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
                    layout="total, prev, pager, next, jumper" :total="pagination1.total"></el-pagination>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    SupplierList,
    AgreeRate,
    RejectRate,
    GetSupplierRate,
    GetRateRecords
} from "@/api/newRate";
import { _formatMoney } from "@/utils/utils";
export default {
    data() {
        return {
            // 分页参数
            pagination: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            pagination1: {
                page: 1, //当前所处的页码
                pagesize: 10, //每次请求的数量
                total: 0, //总条数
            },
            dialogName: 'first',
            TotalInvoiceAmount: 0, // 每月最大金额
            // 筛选条件数据
            manageForm: {
                EnterpriseFullName: "",
                LegalPersonlPhone: "",
                TaskDatetime: ["", ""],
            },
            // 数据列表
            tableData: [],
            // 数据请求参数
            ConfigStatus: 10,
            // 弹窗集合
            flag: {
                // 是否显示退回意见弹窗
                centerDialogVisible: false,
                // 查看费率
                rateMdel: false
            },
            // 退回意见文本
            textarea: "",
            // 表格loading
            loading: true,
            // 服务商信息
            serveInfo: '',
            // 费率信息
            rateData: [],
            rateDataAgent: [],
            rateRecord: [],
            rateRecordDialog: false,
            currentItem: {}
        };
    },
    activated() {
        // 获取列表数据
        this.BySupplierLists();
    },
    created() {
        // 获取数据列表
        // this.BySupplierList();
    },
    methods: {
        searchChange() {
            this.search()
        },
        // 查看费率
        examineRate(item) {
            this.serveInfo = item
            this.dialogName = 'first'
            GetSupplierRate({ userid: item.UserID }).then(res => {
                let ratelist = []
                let eInvoiceTypeListAgent = []
                res.data.SupplierRate.forEach(item => {
                    item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
                    item.TecRate = (item.TecRate == 0 || item.TecRate == null) ? 0.3 : item.TecRate
                    ratelist.push(item)
                })
                res.data.AgentRate.forEach(item => {
                    item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
                    eInvoiceTypeListAgent.push(item)
                })
                this.TotalInvoiceAmount = _formatMoney(res.data.TotalInvoiceAmount)
                this.rateData = ratelist
                this.rateDataAgent = eInvoiceTypeListAgent
                this.flag.rateMdel = true;
            })
        },
        // 查看历史记录
        toDetail(item) {
            this.currentItem = item
            this.getRateRecords()
        },
        getRateRecords() {
            this.loading = true
            GetRateRecords({
                pageSize: this.pagination1.pagesize,
                pageIndex: this.pagination1.page,
                rateID: this.currentItem.RateID
            }).then(res => {
                this.rateRecord = res.data.DataList
                this.pagination1.total = res.data.TotalCount;
                this.rateRecordDialog = true
            }).finally(() => {
                this.loading = false
            })
        },
        // 提交退回意见
        bindBack() {
            if (this.textarea) {
                let data = {
                    optionID: this.serveInfo.OptionID,
                    remark: `驳回意见：${this.textarea}`,
                };
                RejectRate(data).then((res) => {
                    this.search();
                    this.$message.success("操作成功")
                    this.flag.centerDialogVisible = false;
                    this.textarea = "";
                });
            } else {
                this.$message.error("请输入驳回意见");
            }
        },
        // 退回重设
        backClick(item) {
            this.serveInfo = item
            this.textarea = "";
            this.flag.centerDialogVisible = true;
        },
        // 同意合作分成比例
        agreeClick(item) {
            let data = {
                optionID: item.OptionID,
            };
            AgreeRate(data).then((res) => {
                this.$message.success("操作成功")
                this.search();
            });
        },
        // 清空搜索条件
        resetForm() {
            this.manageForm = {
                EnterpriseFullName: "",
                LegalPersonlPhone: "",
                TaskDatetime: ["", ""],
            };
            this.search();
        },
        // 搜索
        search() {
            this.pagination.page = 1
            this.BySupplierLists();
        },
        //分页控件页码change事件回调
        handleCurrentChange(e) {
            this.pagination.page = e;
            this.BySupplierLists();
        },
        handleCurrentChange1(e) {
            this.pagination1.page = e;
            this.getRateRecords();
        },
        // 设置服务商分成比例列表
        BySupplierLists() {
            this.loading = true
            let data = {
                enterpriseFullName: this.manageForm.EnterpriseFullName,
                legalPersonlPhone: this.manageForm.LegalPersonlPhone,
                createdTimeStart: (this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[0]) ? this.manageForm.TaskDatetime[0] + ' 00:00:00' : '',
                createdTimeEnd: (this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[1]) ? this.manageForm.TaskDatetime[1] + ' 23:59:59' : '',
                pageSize: this.pagination.pagesize,
                pageIndex: this.pagination.page,
                status: this.ConfigStatus
            }
            SupplierList(data).then((res) => {
                this.tableData = res.data.DataList;
                this.pagination.total = res.data.TotalCount;
            }).finally(() => {
                this.loading = false;
            })
        },
        // 查看详情
        goFacility(item, pageIndex) {
            this.$router.push({
                path: "/auditInfo/auditService/agreeInfo",
                query: {
                    UserID: item.UserID,
                    pageIndex
                },
            });
        },
    }
};
</script>

<style lang="scss">
.rate-dialog {
    .el-dialog__body {
        padding-top: 0px;
    }
}

.wrap {
    .el-input.is-disabled .el-input__inner {
        color: #333 !important;
    }
}
</style>

<style lang="scss" scoped>
.mdoeBox {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    font-size: 16px;
    text-align: center;
    box-sizing: border-box;
    margin-top: 10px;
    padding: 20px 0 0 0;
}

.mdoeDiv {
    font-size: 16px;
    width: 80%;
    margin: 10px 10% 0;
    font-weight: bold;
    color: red;
}

.dialog_box {
    font-size: 14px;
    line-height: 26px;
    width: 100%;
    background: #f8f8f8;
    padding: 20px 1%;
    box-sizing: border-box;
    color: #666;
    border-radius: 5px;

    .dialogRole {
        width: 100%;
        text-align: center;
    }
}

.deep_dialog {
    ::v-deep .el-dialog__header {
        border-bottom: 1px solid #eee;
    }

    ::v-deep .el-dialog__footer {
        border-top: 1px solid #eee;
        text-align: center;
    }

    ::v-deep .el-dialog__body {
        padding: 0px 20px 20px;
    }
}
</style>